body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-stone-lightest);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.grid:after {
  clear: both;
  content: '';
  display: block;
}

.grid-sizer,
.grid-item {
  width: 100%;
}

.gutter-sizer {
  width: 0;
}

@media screen and (min-width: 992px) {
  .grid-sizer,
  .grid-item {
    width: 49%;
  }

  .gutter-sizer {
    width: 2%;
  }
}
